<!-- 论文摘要 -->
<template>
  <div class="abstract">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/abstract/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title" :class="{ weibei: lang }">
              {{ $t("abstracts") }}
            </div>
          </div>

          <div class="header h5">
            <img
              src="@/assets/images/abstract/banner-h5.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title" :class="{ weibei: lang }">
              {{ $t("abstracts") }}
            </div>
          </div>

          <div class="lcontainer container">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("secondGlobalBamboo") }}
            </div>
            <div class="ctitle" :class="{ weibei: lang }">
              {{ $t("callForAbstracts") }}
            </div>
            <!-- <div class="t-c" v-if="!lang" style="text-align:center">Theme：</div> -->
            <div class="ctime" v-if="lang">{{ $t("abstractsTime") }}</div>
            <p class="m-t-38 m-b-24">
              {{ $t("absp1") }}
            </p>

            <div class="subtitle-box">
              <div class="subbtitle m-t-34 m-b-14">
                {{ $t("absp2") }}
              </div>
              <p>{{ $t("absp3") }}</p>
              <div class="subbtitle m-t-34 m-b-14">
                {{ $t("absp4") }}
              </div>
              <p>{{ $t("absp5") }}</p>

              <div class="subbtitle m-t-34 m-b-14">
                {{ $t("absp6") }}
              </div>
              <p>{{ $t("absp7") }}</p>
              <div class="subbtitle m-t-34 m-b-14">
                {{ $t("absp8") }}
              </div>
              <p>{{ $t("absp9") }}</p>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("absp10") }}
              </div>
              <p class="m-b-14">{{ $t("absp11") }}</p>
              <p class="m-b-14">{{ $t("absp12") }}</p>
              <p>{{ $t("absp13") }}</p>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("absp14") }}
              </div>
              <div class="tab-box pc">
                <!-- <el-image style="width: 100%;" :src="tableImg" :fit="'fill'">
                </el-image> -->

                <table
                  border="1"
                  cellspacing="0"
                  style="width: 100%; border: none"
                >
                  <tbody>
                    <tr style="height: 30px; background: #8dc63f">
                      <td valign="">
                        <p>
                          <span style="color: #fff; font-weight: bold">{{
                            lang ? "事 项" : "Item"
                          }}</span>
                        </p>
                      </td>
                      <td valign="">
                        <p>
                          <span style="color: #fff; font-weight: bold">{{
                            lang ? "日 期" : "Date"
                          }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span>{{ $t("abstractTable.a") }}</span>
                        </p>
                      </td>
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span>{{ $t("abstractTable.b") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span> {{ $t("abstractTable.c") }}</span>
                        </p>
                      </td>
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span> {{ $t("abstractTable.d") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span> {{ $t("abstractTable.e") }}</span>
                        </p>
                      </td>
                      <td valign="" style="padding-left: 4px">
                        <p>
                          <span> {{ $t("abstractTable.f") }}</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-box h5">
                <!-- <el-image style="width: 100%;" :src="tableImg" :fit="'fill'">
                </el-image> -->
                <table
                  border="1"
                  cellspacing="0"
                  style="width: 100%; border: none"
                >
                  <tbody>
                    <tr style="height: 30px; background: #8dc63f">
                      <td valign="">
                        <p>
                          <span style="color: #fff; font-weight: bold">{{
                            lang ? "事 项" : "Item"
                          }}</span>
                        </p>
                      </td>
                      <td valign="">
                        <p>
                          <span style="color: #fff; font-weight: bold">{{
                            lang ? "日 期" : "Date"
                          }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="">
                        <p>
                          <span>{{ $t("abstractTable.a") }}</span>
                        </p>
                      </td>
                      <td valign="">
                        <p>
                          <span>{{ $t("abstractTable.b") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="">
                        <p>
                          <span> {{ $t("abstractTable.c") }}</span>
                        </p>
                      </td>
                      <td valign="">
                        <p>
                          <span> {{ $t("abstractTable.d") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td valign="">
                        <p>
                          <span> {{ $t("abstractTable.e") }}</span>
                        </p>
                      </td>
                      <td valign="">
                        <p>
                          <span> {{ $t("abstractTable.f") }}</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("absp15") }}
              </div>
              <p class="m-b-14">{{ $t("absp16") }}</p>
              <p class="m-b-14">{{ $t("absp17") }}</p>

              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("absp188") }}
              </div>

              <p v-html="$t('absp199')" class="m-b-14"></p>
              <!-- <p class="m-t-42 m-b-14" v-html="$t('absp18')"></p> -->
              <!-- <p v-html="$t('absp19')" class="m-b-14"></p> -->
              <p class="m-b-14" v-html="$t('absp20')"></p>
              <!-- <p class="m-b-14" v-html="$t('adsp21')"></p> -->
              <p class="m-b-14" v-html="$t('adsp22')"></p>
              <p>{{ $t("adsp23") }}</p>
            </div>
          </div>

          <div v-if="1" class="displays">
            <div class="left item">
              <img
                src="../../assets/images/abstract/z1.png"
                class="labImg"
                alt=""
              />
              <div class="btnBox" @click="openType(1)">
                <img src="../../assets/images/xz.png" class="xdIcon" alt="" />
                点击下载
              </div>
            </div>
            <div class="right item">
              <img
                src="../../assets/images/abstract/z2.png"
                class="labImg"
                alt=""
              />
              <div class="btnBox" @click="openType(2)">
                <img src="../../assets/images/xz.png" class="xdIcon" alt="" />
                Download
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableImg: null,
    };
  },
  created() {
    this.innit();
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    openType(i) {
      if (i == 1) {
        window.open("https://www.barc2022.inbar.int/barcfile/zy1.pdf");
      } else {
        window.open("https://www.barc2022.inbar.int/barcfile/zy2.pdf");
      }
    },
    gourl(url) {
      window.open(url);
    },
    innit() {
      if (window.localStorage.getItem("locale") == "zh-CN") {
        this.tableImg = require("../../assets/images/abstract/table.png");
      } else {
        this.tableImg = require("../../assets/images/abstract/table-en.png");
      }
    },
  },
};
</script>
<style lang="stylus">
@media screen and (min-width: 728px) {
  .abstract {
    .header {
      .el-image {
        display: block;
      }
    }
  }

  .displays {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    .item {
      flex: 1;
    }
  }

  .labImg {
    width: 40%;
    border: 2px solid #eeeeee;
  }

  .btnBox {
    width: 230px;
    height: 50px;
    margin: auto;
    background: #8dc32b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;

    .xdIcon {
      width: 28px;
      margin-right: 10px;
    }
  }
}
</style>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .abstract {
    table, td, th {
      // border: 0.5px solid #f2f2f2;
    }

    background: #FAFAFA;
    width: 100%;
    overflow: hidden;

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 42px;
        }
      }

      .container {
        width: 100%;
        max-width: 1520px;
        margin: 0 auto 36px auto;
        padding: 69px 60px 56px 60px;
        text-align: left;
        box-sizing: border-box;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 39px;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          margin-top: 16px;
          text-decoration: underline;
          cursor: pointer;
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 24px;
        }

        .subtitle-box {
          text-align: left;

          .subbtitle {
            font-size: 16px;
          }

          .subtitle {
            font-size: 20px;
            color: #405E3C;
            margin: 43px 0 29px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }

        .tab-box {
          width: 627px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 728px) {
    .displays {
    margin-top: 10px
    .item {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .labImg {
    width: 100%;
    border: 4px solid #eeeeee;
    box-sizing: border-box;
  }

  .btnBox {
    width: 140px;
    height: 40px;
    margin: auto;
    background: #8dc32b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;

    .xdIcon {
      width: 18px;
      margin-right: 6px;
    }
  }
  .abstract {
    width: 100%;
    overflow: hidden;

    .pageBox {
      p, div {
        font-size: 14px;
      }

      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          width: 164px;
          height: 60px;
          line-height: 60px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 22px;
        }
      }

      .container {
        padding: 20px;
        margin-top: 20px;
        text-align: left;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 1.5;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          text-decoration: underline;
          // margin-top 10px
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 6px;
        }

        .subtitle-box {
          text-align: left;

          .subbtitle {
            font-size: 14px;
          }

          .subtitle {
            font-size: 20px;
            color: #405E3C;
            margin: 43px 0 29px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .tab-box {
    text-align: center;
  }
}

td {
  border: 0.5px solid #f2f2f2;
}
</style>